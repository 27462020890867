exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-examples-gatsby-404-tsx": () => import("./../../../src/pages/examples/gatsby-404.tsx" /* webpackChunkName: "component---src-pages-examples-gatsby-404-tsx" */),
  "component---src-pages-examples-index-old-tsx": () => import("./../../../src/pages/examples/index-old.tsx" /* webpackChunkName: "component---src-pages-examples-index-old-tsx" */),
  "component---src-pages-examples-pagetest-tsx": () => import("./../../../src/pages/examples/pagetest.tsx" /* webpackChunkName: "component---src-pages-examples-pagetest-tsx" */),
  "component---src-pages-examples-testblog-tsx": () => import("./../../../src/pages/examples/testblog.tsx" /* webpackChunkName: "component---src-pages-examples-testblog-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../../../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-resume-mdx": () => import("./../../../src/pages/resume.mdx" /* webpackChunkName: "component---src-pages-resume-mdx" */),
  "component---src-pages-test-mdx": () => import("./../../../src/pages/test.mdx" /* webpackChunkName: "component---src-pages-test-mdx" */)
}

